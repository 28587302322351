.product-img:hover {
    .product-hover {
        background: #87b4b2a3 !important;
        backdrop-filter: blur(2px);
    }

    .simple-list {
        opacity: 1;
        display: flex !important;
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);

        -webkit-transition: transform 0.2s ease-in-out;
        -moz-transition: transform 0.2s ease-in-out;
        -ms-transition: transform 0.2s ease-in-out;
    }
}

.simple-list {
    transform: scale(0);
    border-radius: 20px;
    width: 40px;
    height: 40px;
    background-color: aliceblue;
    display: flex;
    justify-content: space-evenly;
    margin: 0px;
    align-items: center;
}

.img {
    margin-bottom: 0px;
    margin-top: 0px
}

.product-img {
    position: relative;

    .product-hover {
        position: absolute;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

ul {
    list-style-type: none;
}