.modal-content {
    background-color: transparent !important;
    border: 0px !important;
    color: white !important;
}

.modal-title {
    color: white !important;
}

.modal-content .btn-close {
    color: white !important;
}

#aniimated-thumbnials14 {
    display: contents !important;
}