.accordion-button {
    display: flex !important;
    justify-content: space-between !important;
}

.accordion-button::after {
    margin-right: 0px !important;
    margin-left: none !important;
}

.card-header {
    background: #7bd6d04f !important;
    /* 7bd6d04f green */
    /* 7ba9d64f blue */
}

.text-truncate {
    text-decoration: none;
}