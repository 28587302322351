.slide:hover,
.slide:focus {
    box-shadow:
        inset -5.5em 0 0 0 var(--hover),
        inset 5.5em 0 0 0 var(--hover);
    color: #ceebee !important;
}


$colors: (
    slide: #089bab
);

@each $button,
$color in $colors {
    .#{$button} {
        --color: #{$color};
        --hover: #{adjust-hue($color, 0deg)};
    }
}

.iq-bg-primary {
    color: var(--color);
    transition: 0.25s;

    &:hover,
    &:focus {
        border-color: var(--hover);
        color: #fff;
    }
}