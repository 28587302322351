.tabsFilter {
    /* background-color: ''; */
    padding-right: 20px !important;
    padding-left: unset !important;
    color: #898989 !important;
    line-height: 39px !important;
    text-transform: uppercase !important;
    border-radius: 3px !important;
    font-weight: 500 !important;
}

.tabsFilter:hover {
    background-color: rgba(36, 105, 92, 0.1);
    text-decoration: none;
}

.card-header {
    background: #fff !important;
}