.flip {
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
    padding: 1em;
    width: 100%;
    height: auto;
    transform-style: preserve-3d;
    transition: 1.2s ease;
}

.flip:hover {
    transform: rotateY(180deg);
}

/* Content */
.flip .content {
    transform-style: preserve-3d;
}

.flip .back,
.flip .front {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly
}

.flip .back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.imgg {
    transform: translateZ(90px);
    text-shadow: 0 0 3px black;
    text-align: center;
    border: 5px solid transparent;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.flip h4,
.flip p,
.flip img,
.socialMedia {
    transform: translateZ(90px);
    text-shadow: 0 0 1px black;
    text-align: center;
    line-height: 1.6em;
}

.flip h4 {
    font-size: 1.3em;
    color: #070707;
    /* letter-spacing: 1px; */
}

.flip p {
    font-size: 1em;
    color: #000000;
    /* line-height: 1.5em; */
}

.flip::before,
.flip::after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    backdrop-filter: blur(4px);
    /* background: rgba(255, 255, 255, 0.3); */
    z-index: -1;
    background-image: linear-gradient(rgba(255, 166, 0, 0.085), rgb(130, 238, 234));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotateY(180deg)translateZ(1px);
}

.flip::before {
    transform: none;
    background-image: linear-gradient(rgb(130, 238, 234), rgba(255, 166, 0, 0.085));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}



.parent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.child {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
    border-radius: 50%;
    margin: 0 5px;
}

.child:hover {
    background-color: white;
    background-position: -100px 100px, -100px 100px;
    transform: rotate3d(0.5, 1, 0, 30deg);
    transform: perspective(180px) rotateX(60deg) translateY(2px);
    box-shadow: 0px 10px 10px rgb(1, 49, 182);
}

button {
    border: none;
    background-color: transparent;
    font-size: 20px;
}

.button:hover {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0px, 0px, 15px) perspective(180px) rotateX(-35deg) translateY(2px);
    /* border-radius: 50%; */
}