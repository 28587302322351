.cardHome {
    background: #d6eaf1ab;
    border-radius: 5px;
    display: inline-block;
    height: 200px;

    p {
        color: black;
        opacity: 0;
    }

    .colCard:hover & {
        transform: scale(1.1);

        .img {
            transform: scale(0.6);
            top: -23px;
            margin-bottom: 10px;
            fill: white;
        }

        p {
            position: relative;
            animation: paragraphMove 0.4s;
            opacity: 1;
            top: -23px;
            color: white;
        }

        h4 {
            color: white;
        }

        .iconWthTitle {
            margin-top: 0px;
        }
    }

}

.colCard {
    box-sizing: border-box;
}

.img {
    fill: #429eb7;
    margin-bottom: 20px;
    margin-top: 20px;
}

.cardHome:hover {
    background: #089bab;
}

h4 {
    position: relative;
    transition: top 0.5s ease 0s;
    top: 0;
    font-weight: 400;
}

.colCard:hover h4 {
    top: -23px;
}

.cardHome {
    transition: all 0.5s;
}


.img {
    transition: all 0.5s;
}

@keyframes paragraphMove {
    from {
        scale: 2;
        opacity: 0;
    }

    to {
        scale: 1;
        opacity: 1;
    }
}

.iconWthTitle {
    margin-top: 30px;
}

* {
    font-family: 'Cairo', sans-serif !important;
}

/* .iq-footer {
    position: fixed;
} */

.center {
    margin-bottom: 12%;
    margin-top: 5%;
}

.centerRow {
    position: relative;

    transform: translate(0%, 10%);
}

@media only screen and (max-width: 600px) {
    .center {
        margin-bottom: 12%;
        margin-top: 0%;
    }

}