.container {
    position: relative;
    perspective: 1000px;
    /* height: 80vh; */
}

.logo-img {
    width: 70%;
    height: auto;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
    filter: drop-shadow(0 0 1rem rgb(142, 183, 180));
}

.logo-img:hover {
    transform: perspective(1000px) rotateY(-20deg);
    /* filter: drop-shadow(0 0 0.75rem rgb(142, 183, 180)); */
}


.text-bar {
    background: #e3f2fd;
    /* Light blue background color */
    padding: 10px 20px;
    display: inline-block;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: sweetBar 3s ease-in-out infinite alternate;
}

@keyframes sweetBar {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.03);
    }
}

.text-bar span {
    color: #000;
    /* Dark text color */
    font-size: 1.1em;
    font-weight: 601;
}


.text-bar-2 {
    background: #ffffff1a;
    /* Light green background color */
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.text-bar-2 p {
    color: #141514;
    /* Green text color */
    font-weight: bold;
    font-size: 1.2em;
    text-align: right;
}

.text-bar-2:hover {
    transform: scale(1.05);
    -webkit-transition: transform 0.2s ease-in-out;
    -moz-transition: transform 0.2s ease-in-out;
    -ms-transition: transform 0.2s ease-in-out;
}

.text-bar2 {
    background: linear-gradient(to right, #f7f7f7, #e8e8e8);
    /* Soft gradient background */
    color: #333;
    /* Dark text color */
    padding: 10px 20px;
    display: inline-block;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: moveBar 3s ease-in-out infinite alternate;
}

@keyframes moveBar {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(5px);
    }
}

.text-bar2 span {
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.text-bar2 span:first-of-type {
    font-size: 1.1em;
}



.container-founder {
    /* height: 100vh; */
    width: 100%;
    /* max-height: 800px;
    max-width: 1280px;
    min-height: 600px;
    min-width: 1000px; */
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
    /* margin: 0 auto */
}

.border-founder {
    height: 369px;
    width: 290px;
    background: transparent;
    border-radius: 10px;
    transition: border 1s;
    position: relative;


    &:hover {
        border: 1px solid white
    }
}

.card-founder {
    height: 279px;
    width: 300px;
    background: grey;
    border-radius: 10px;
    transition: background 0.8s;
    overflow: hidden;
    background: black;
    box-shadow: 0 70px 63px -60px #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.card0 {
    background: url('../../assets/images/login/Initiatives.png') center center no-repeat;
    background-size: contain;
}

.card1 {
    background: url('../../assets/images/login/ISSAD.png') center center no-repeat;
    background-size: contain;
}

.card2 {
    background: url('https://i.pinimg.com/originals/ee/85/08/ee850842e68cfcf6e3943c048f45c6d1.jpg') center center no-repeat;
    background-size: contain;
}

h2 {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: white;
    margin: 20px;
    opacity: 0;
    transition: opacity 1s
}

.fa {
    opacity: 0;
    transition: opacity 1s
}

.icons-founder {
    position: absolute;
    fill: #fff;
    color: #fff;
    height: 130px;
    top: 226px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around
}

/*---------------------------------------------------------------------*/

.slider-partners {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.slider-partners::before,
.slider-partners::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider-partners::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider-partners::before {
    left: 0;
    top: 0;
}

.slider-partners .slide-track-partners {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.slider-partners .slide-partners {
    height: auto;
    width: 100%;
}

/*---------------------------------------------------------------------*/