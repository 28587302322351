.cardsStyled {
  width: 23%;
  height: 300px;
  background: white;
  border-radius: 32px;
  padding: 3px;
  position: relative;
  box-shadow: #604b4a30 0px 30px 10px -3 0px;
  transition: all 0.5s ease-in-out;
}

.cardsStyled .mail {
  position: absolute;
  right: 2rem;
  top: 1.4rem;
  background: transparent;
  border: none;
}

.cardsStyled .mail svg {
  stroke: #b6fbe9;
  stroke-width: 3px;
}

.cardsStyled .mail svg:hover {
  stroke: #4ccfbf;
}

.cardsStyled .profile-pic {
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  top: 3px;
  left: 3px;
  border-radius: 29px;
  z-index: 1;
  border: 0px solid #b6fbe9;
  overflow: hidden;
  transition: all 0.5s ease-in-out 0.2s, z-index 0.5s ease-in-out 0.2s;
}

.cardsStyled .profile-pic img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  -o-object-position: 0px 0px;
  object-position: 0px 0px;
  transition: all 0.5s ease-in-out 0s;
}

.cardsStyled .profile-pic .svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 0px;
  object-position: 50% 0px;
  transform-origin: 45% 20%;
  transition: all 0.5s ease-in-out 0s;
}

.cardsStyled .bottom {
  position: absolute;
  bottom: 3px;
  left: 3px;
  right: 3px;
  background: #b6fbe9;
  top: 80%;
  border-radius: 29px;
  z-index: 2;
  box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px inset;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.cardsStyled .bottom .content {
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  right: 1.5rem;
  height: 190px;
}

.cardsStyled .bottom .content .name {
  display: block;
  font-size: 1.1rem;
  color: black;
  font-weight: bold;
}

.cardsStyled .bottom .content .about-me {
  display: block;
  font-size: 0.9rem;
  color: black;
  margin-top: 1rem;
}

.cardsStyled .bottom .bottom-bottom {
  position: absolute;
  bottom: 1rem;
  left: 1.5rem;
  right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardsStyled .bottom .bottom-bottom .social-links-container {
  display: flex;
  gap: 1rem;
}

.cardsStyled .bottom .bottom-bottom .social-links-container svg {
  height: 20px;
  fill: white;
  filter: drop-shadow(0 5px 5px rgba(165, 132, 130, 0.1333333333));
  cursor: pointer;
}

.cardsStyled .bottom .bottom-bottom .social-links-container svg:hover {
  fill: #4ccfbf;
  transform: scale(1.2);
}

.cardsStyled .bottom .bottom-bottom .medic-button {
  background: white;
  color: #4ccfbf;
  border: none;
  border-radius: 20px;
  font-size: 0.6rem;
  padding: 0.4rem 0.6rem;
  box-shadow: rgba(165, 132, 130, 0.1333333333) 0px 5px 5px 0px;
}

.cardsStyled:hover {
  border-top-left-radius: 55px;
}

.cardsStyled:hover .bottom {
  top: 20%;
  border-radius: 80px 29px 29px 29px;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
}

.cardsStyled:hover .profile-pic {
  width: 100px;
  height: 100px;
  aspect-ratio: 1;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  z-index: 3;
  border: 7px solid #b6fbe9;
  box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px;
  transition: all 0.5s ease-in-out, z-index 0.5s ease-in-out 0.1s;
}

.cardsStyled:hover .profile-pic:hover {
  transform: scale(1.3);
  border-radius: 0px;
}

.cardsStyled:hover .profile-pic img {
  transform: scale(1);
  -o-object-position: 0px 25px;
  object-position: 0px 25px;
  transition: all 0.5s ease-in-out 0.5s;
}

.cardsStyled:hover .profile-pic .svg {
  transform: scale(1);
  transition: all 0.5s ease-in-out 0.5s;
}

.MyWrapper {
  background-color: #ffffff00;
}

.MyWrapper .medic-button {
  display: inline-block;
  height: 40px;
  width: 40px;
  /* float: right; */
  margin: 0 5px;
  overflow: hidden;
  background: #ffffff02;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}

.MyWrapper .medic-button:hover {
  width: 300px;
}

.MyWrapper .medic-button .icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 35px !important;
}

.MyWrapper .medic-button:nth-child(1) .icon {
  background: #b6fbe9;
}

.wrapper .medic-button .icon i {
  font-size: 25px;
  line-height: 35px;
}

.MyWrapper .medic-button span {
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  margin-right: 10px;
  color: #000;
}


.wrapBot .medic-button .icon {
  text-align: left;
}

.wrapBot .medic-button .icon i {
  line-height: 25px !important;
}

.wrapBot .medic-button:hover {
  width: 170px !important;
}

.wrapBot .medic-button:nth-child(1) .icon {
  background: #ffffff;
}