.logoImg {
    width: 60% !important;
}

@media only screen and (max-width: 480px) {
    .logoImg {
        width: 100% !important
    }
}

@media only screen and (max-width: 767px) and (min-width: 481px) {

    .logoSide {
        width: 30% !important
    }
}


.text-justify {
    margin: 0;
    /* text-indent: 2rem; */
    text-align: center;
}

.txtAbout {
    font-size: 18px !important;
}