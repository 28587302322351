.cardsCat {
    width: 30%;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.cardsCat .red {
    background-color: #62abbd9e;
}

.cardsCat .blue {
    background-color: #0062ff99;
}

.cardsCat .green {
    background-color: #18cd5e82;
}

.cardsCat .cardCat {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 200px;
    width: 200px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
}

.cardsCat .cardCat p.tip {
    font-size: 1em;
    font-weight: 700;
}

.cardsCat .cardCat p.second-text {
    font-size: 1.5em;
}

.cardCat:hover {
    filter: blur(0px);
    transform: scale(1.2, 1.2);
}

.cardsCat:hover>.cardCat:not(:hover) {
    filter: blur(10px);
    transform: scale(0.9, 0.9);
}


@media only screen and (max-width: 442px) {
    .cardsCat {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 15px;
    }

    .cardsCat .cardCat:hover {
        transform: scale(1.2, 1.2);
    }

    .cardsCat:hover>.cardCat:not(:hover) {
        filter: blur(0px);
        transform: scale(0.9, 0.9);
    }

    .cardCat {
        height: 160px;
        width: 160px;
    }
}

@media only screen and (max-width: 888px) {

    .cardsCat {
        width: 100%;
        gap: 15px;
    }

}